@import "@/styles/shared";
.Navigation {
  @include z-index(navigation);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 0 var(--page-gutter);
  opacity: 1;
  pointer-events: all;
  transition: opacity $transition-short;
  transition-delay: $transition-short;

  @each $colour in $background-colours {
    &[data-theme='#{$colour}'] {
      @if $colour == black or $colour == burgundy {
        @include dark-theme;
      }
    }
  }

  &.loading {
    opacity: 0;
    pointer-events: none;
  }
}

.headerBg {
  @include z-index(navigation-bg);
  transition: background-color $transition-short, height $transition-short, $transition-short opacity $transition-short;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: var(--header-height);

  @include bp(xsmobile, true) {
    height: calc(var(--header-height-xsmobile));
  }

  @each $colour in $background-colours {
    &[data-theme='#{$colour}'] {
      background-color: var(--#{$colour});

      @if $colour == black or burgundy {
        @include dark-theme;
      }
    }
  }

  &.isGlassyBg:not(.navIsOpen) {
    background: none;

    @include bp(laptop) {
      backdrop-filter: saturate(180%) blur(px(20));
    }
  }

  &.isTransparentBg:not(.navIsOpen) {
    background: none;
    backdrop-filter: none;
  }

  &.navIsOpen {
    top: auto;
    @include bp(laptop, true) {
      height: 100vh;
    }
  }

  &.stickyIsOpen {
    top: var(--stickybanner-height);
    @include bp(xsmobile, true) {
      top: var(--stickybanner-height-xsmobile);
    }
  }

  &.stickyIsOpenAndNavIsOpen {
    @include bp(laptop, true) {
      height: 100vh;
    }
  }

  &.loading {
    opacity: 0;
  }
}

.nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  margin: 0 auto;
  height: var(--header-height);
}

.logoContainer {
  transition: opacity $transition-short;

  .hideLogoAndButtons & {
    opacity: 0;
  }
}

.logoDesktop,
.logoMobile {
  color: var(--text-color);
  transition: color $transition-short;
}

.logoMobile {
  display: block;
  width: px(40);

  @include bp(laptop) {
    display: none;
  }
}

.logoDesktop {
  display: none;
  width: px(163);

  @include bp(laptop) {
    display: block;
  }
}

.canadaLogoMobile {
  width: auto;
  height: px(60);  
}

.canadaLogoDesktop {
  width: px(222);
  height: px(60);  
}

.desktopLinkList {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  align-items: center;
  justify-content: center;
  gap: px(48);
  display: none;

  @include bp(laptop) {
    display: flex;
  }
}

.desktopLinkList__item {
  position: relative;
  display: none;

  @include bp(laptop) {
    display: block;
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    pointer-events: none;
    top: 100%;
    width: 100%;
    height: 1px;
    left: 50%;
    transform: translateX(-50%) scaleX(0);
    background-color: var(--text-color);
    transition: transform $transition-short;
  }

  &.isActive::after {
    transform: translateX(-50%) scaleX(1);
  }
}

.desktopLinkList__item__link {
  @include font-button;
  color: var(--text-color);
  transition: color $transition-short;
  white-space: nowrap;
}

.buttons__desktop {
  display: none;
  transition: opacity $transition-short;
  opacity: 1;

  .hideLogoAndButtons & {
    opacity: 0;
  }

  @include bp(laptop) {
    display: block;
  }
}

.mobileToggleButton {
  @include reset-button;
  @include box(px(56));
  position: relative;
  border-radius: 50%;
  color: var(--button-secondary-label-color);
  border-color: var(--button-secondary-outline-color);
  background-color: var(--button-secondary-background-color);
  border-width: px(1);
  border-style: solid;
  overflow: hidden;

  &.hover {
    color: var(--button-secondary-label-hover-color);
    border-color: var(--button-secondary-outline-hover-color);
    background-color: var(--button-secondary-background-hover-color);
  }

  @include bp(laptop) {
    display: none;
  }
}

.mobileToggleButton__hamburgerContainer,
.mobileToggleButton__closeContainer {
  @include position-100(absolute);
  @include flex-center;
  transition: opacity $transition-short, transform $transition-short;

  svg {
    width: px(24);
    transition: color $transition-short;
  }
}

.mobileToggleButton__hamburgerContainer {
  transform: none;
  opacity: 1;

  .navIsOpen & {
    opacity: 0;
    transform: translateY(#{px(-24)});
  }
}

.mobileToggleButton__closeContainer {
  transform: translateY(#{px(24)});
  opacity: 0;

  .navIsOpen & {
    opacity: 1;
    transform: none;
  }
}
